<template>
  <v-dialog width="400" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-icon style="margin-top: 6px; margin-right: 0">
          <v-icon small>$refund</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span>{{ $tc("refund") }} </span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title> {{ $tc("refund") }} </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="refund-form"
        @submit.prevent="validateRefund('refund-form')"
      >
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" sm="7" class="py-0 mt-3">
              <v-radio-group
                row
                v-model="refund.type"
                class="pl-3"
                data-vv-name="type"
                hide-details
                :error-messages="errors.collect('refund-form.type')"
                data-vv-validate-on="blur"
                v-validate="'required'"
              >
                <v-radio color="primary" value="total">
                  <template v-slot:label
                    ><div>
                      {{ $t("refunds.types.total") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio color=" primary" value="parcial">
                  <template v-slot:label
                    ><div>
                      {{ $t("refunds.types.parcial") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="5" class="py-0 pl-sm-0">
              <label style="height: 25px; display: block">{{
                $t("refunds.cantidad")
              }}</label>

              <number-input-with-separators
                :value="refund"
                valueKey="cantidad"
                ref="money"
                rules="required|is_not:0€"
                outlined
                :disabled="refund.type !== 'parcial'"
                dense
                class="input_cantidad"
                type="number"
                v-on:keypress="isNumber($event)"
                hide-details
                ><template> </template>
              </number-input-with-separators>
            </v-col>
            <v-col cols="12" class="py-0">
              <label>{{ $t("refunds.reason") }}</label>
              <v-text-field
                v-model="refund.reason"
                outlined
                dense
                hide-details
                rows="1"
                name="input-7-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false"
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            ><v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ExpenseRefund",
  props: ["expense"],
  data() {
    return {
      dialog: false,
      refund: { type: null },
    };
  },
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("payments", ["refundPayment"]),
    async validateRefund(scope) {
      this.errors.clear();
      let form_val = await this.$validator.validateAll(scope);
      let money_val = true;
      if (this.$refs.money) money_val = await this.$refs.money.validate();

      if (form_val && (money_val || this.refund.type === "total")) {
        //console.log("validated", this.refund);
        if (this.refund.type === "total")
          this.refund.cantidad = this.expense.cantidad;

        this.refund.id = this.expense.stripe_token;
        this.refund.type = "stripe";
        this.refundPayment({ id: this.refund.id, formData: this.refund }).then(
          () => {
            this.dialog_refund = false;
            this.refund = { type: null };
            this.$emit("update");
          }
        );
        return true;
      }
      return false;
    },
  },
};
</script>